<template>
  <div class="main-container">
    <div class="section-topic">
      <div class="title-header">
        <h1>{{ $t("Package.title") }}</h1>
      </div>
    </div>
    <div class="section-action">
      <div class="search-action">
        <v-text-field class="btn btn-color btn-border btn-border-color" outlined dense prepend-inner-icon="mdi-magnify"
                      :placeholder="$t('Package.search')"
                      v-model="searchItem" @keypress.enter="searchFilterItem"
        >
        </v-text-field>
      </div>
      <div class="btn-new-create-action">
        <v-btn class="btn-create" @click="onCreate()">
          {{ $t("Package.create") }}
        </v-btn>
      </div>
    </div>
    <div class="section-form-content">
      <div class="block-content">
        <table class="table table-view" v-if="listPackage.length > 0">
          <thead>
          <tr>
            <th class="text-start">
              {{ $t("Package.table.No") }}
            </th>
            <th class="text-left">
              {{ $t("Package.table.user") }}
            </th>

            <th class="text-left">
              {{ $t("Package.table.pricePerUser") }}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item,idx) in listPackage" :key="idx">
            <td>
              {{ idx + 1 }}
            </td>
            <td>
              {{ item.min_user }} - {{ item.max_user }}
            </td>
            <td>
              {{ $helpers.numberFormatter(item.price_per_user) }}
            </td>
            <td>

            </td>
            <td class="text-end">
              <v-menu transition="slide-y-transition"
                      bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      outlined
                      v-bind="attrs"
                      v-on="on"
                  >
                    <i class="fas fa-ellipsis-v btn-ellipsis"></i>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-list-item-title class="btn-edit" @click="onEdit(item.id)">Edit</v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title class="btn-delete" @click="onDeleted(item.id)">Delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
          </tbody>
        </table>
        <defaultTableNoResult v-else/>
        <Pagination
            v-if="pagination.total_pages > 1"
            :pagination="pagination"
            :offset="offset"
            @paginate="fetchPackage"
        >
        </Pagination>

        <ModalEdit fullscreen title="ແກ້ໄຂຂໍ້ມູນແພັກແກັດ">
          <template v-slot="{close}">
            <EditPackage :package_item="package_item" @close="close" @success="fetchPackage"/>
          </template>
        </ModalEdit>

        <ModalDelete>
          <template v-slot="{close}">
            <DeletePackage :package_id="package_id" @close="close" @success="fetchPackage"/>
          </template>
        </ModalDelete>


        <Loading v-if="isLoading"/>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "../../../components/Paginate/Pagination";
import Loading from "../../../components/Loading";
import EditPackage from "@/components/Package/Edit";
import DeletePackage from "@/components/Package/Delete";
import defaultTableNoResult from "../../../components/defaultTableNoResult";

export default {
  components: {
    Loading,
    Pagination,
    EditPackage,
    DeletePackage,
    defaultTableNoResult
  },

  data() {
    return {
      offset: 10,
      pagination: {},
      per_page: 10,
      isLoading: true,
      searchItem: "",
      listPackage: [],
      package_item: {},
      package_id: "",
    }
  },
  methods: {
    searchFilterItem() {
      this.fetchPackage();
    },
    onCreate() {
      this.$router.push({name: 'package.create'}).catch(() => {
      });
    },

    filterPackage(packageId) {
      return (
          this.listPackage.filter((item) => {
            return item.id == packageId;
          })[0] || {}
      );
    },

    onEdit(packageId) {
      this.package_item = {
        ...this.filterPackage(packageId)
      };
      this.$store.commit("modalEdit_State", true);
    },

    onDeleted(package_id) {
      this.package_id = package_id;
      this.$store.commit("modalDelete_State", true);
    },

    fetchPackage() {
      this.$axios.get(`admin/list/packages`, {
        params: {
          page: this.pagination.current_page,
          per_page: this.per_page,
          filter: this.searchItem,
        }
      }).then((res) => {
        if (res) {
          console.log(res)
          this.isLoading = false;
          this.listPackage = res.data.data.data;
          console.log(this.listPackage)
          this.pagination = res.data.data.pagination;
          if (!this.listPackage.length > 0) {
            this.pagination.current_page = this.pagination.current_page - 1;
          }
        }
      })
    }
  },
  created() {
    this.fetchPackage();
  }
}
</script>

<style scoped lang="scss">

</style>
